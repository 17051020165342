.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 32px;

  /* stylelint-disable csstools/media-use-custom-media */

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;

    /* stylelint-disable selector-nested-pattern */

    & img {
      width: 50%;
      margin-bottom: 0;
    }
    /* stylelint-enable selector-nested-pattern */
  }
  /* stylelint-enable csstools/media-use-custom-media */

  /* stylelint-disable selector-nested-pattern */

  img {
    font-style: italic;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    shape-margin: 1rem;
    margin-bottom: 12px;
  }
  /* stylelint-enable selector-nested-pattern */

  & .GetStartedButtonContainer {
    display: flex;
    justify-content: center;

    /* stylelint-disable csstools/media-use-custom-media */

    @media (min-width: 768px) {
      justify-content: flex-start;
      margin: 12px;
    }
    /* stylelint-enable csstools/media-use-custom-media */
  }

  /* stylelint-disable declaration-property-value-disallowed-list */

  & .TextAndButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;

    & h2,
    p {
      margin: 12px;
    }
  }
  /* stylelint-enable declaration-property-value-disallowed-list */
}

.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
