.InfoBanner {
  width: 100%;
  position: relative;
  background-color: var(--support03Base);
  border-bottom-left-radius: 48px;
  margin-top: 32px;

  @media (--tablet) {
    @mixin lost-column 11/12;
    @mixin lost-offset 1/12;
    border-bottom-left-radius: 96px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -50vw;
    width: 50vw;
    height: 100%;
    background-color: inherit;
  }

  & .InfoBanner__inner {
    padding: 40px 24px;

    @media (--tablet) {
      @mixin lost-column 8/11;
      @mixin lost-offset 1/11;
      padding: 48px 0 64px;
    }
  }

  & .InfoBanner__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackBase);
    color: var(--grayscaleBlackLighten10);
    position: relative;
    margin: 0 0 16px;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackBase);
      margin-bottom: 24px;
    }
  }

  & .InfoBanner__description {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .InfoBanner__link {
    margin-top: 24px;
  }

  & .InfoBanner__buttonWrapper {
    position: absolute;
    top: -24px;
    right: 0;

    @media (--tablet) {
      top: -32px;
    }
  }

  & .InfoBanner__button.InfoBanner__button {
    @media (--mobileOnly) {
      @mixin largeButton;
    }

    & .SvgIcon {
      fill: var(--grayscaleWhiteBase);
    }
  }
}
