.Banner {
  z-index: 10;
  max-width: 1200px;
  width: 100%;
  background-color: var(--support03Base);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 36px;
  border-radius: 0 0 0 52px;
  margin-top: calc(var(--headerHeight) + 20px);
  margin-right: auto;
  margin-bottom: -100px;
  margin-left: auto;

  @media (--mobileOnly) {
    top: var(--mobileHeaderHeight);
    flex-direction: column;
    align-items: center;
    padding: 12px;
    margin-top: var(--mobileHeaderHeight);
    margin-bottom: 0;
  }

  & h2,
  p {
    padding: 0;
    margin: 0;
  }

  & h2 {
    font-size: 1.8em;
    margin-bottom: 12px;
  }

  & p {
    font-size: 1.2em;
  }

  & button {
    margin-bottom: 6px;

    @media (--mobileOnly) {
      margin-top: 16px;
    }
  }

  & .Banner__headingText {
    width: 70%;

    @media (--mobileOnly) {
      width: 100%;
      padding: 16px;
    }
  }

  & .Banner__buttonAndLinkContainer {
    align-content: flex-end;

    @media (--mobileOnly) {
      margin: 20px;
    }

    & a {
      background-color: var(--primaryBase);
      text-decoration: none;
      font-size: 1.2em;
      font-weight: bold;
      color: var(--grayscaleWhiteBase);
      padding: 12px;
      border-radius: 48px;

      @media (--mobileOnly) {
        font-size: 1.1em;
      }
    }
  }

  & .Banner__crossButton {
    position: absolute;

    @media (--mobileOnly) {
      top: 10px;
      right: 20px;
    }

    @media (--tablet) {
      top: 10px;
      right: 20px;
    }

    @media (--desktop) {
      right: -20px;
      top: -20px;
    }
  }
}
